// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-choco-cream-rolls-js": () => import("./../../../src/pages/choco-cream-rolls.js" /* webpackChunkName: "component---src-pages-choco-cream-rolls-js" */),
  "component---src-pages-cocoa-waltz-cake-js": () => import("./../../../src/pages/cocoa-waltz-cake.js" /* webpackChunkName: "component---src-pages-cocoa-waltz-cake-js" */),
  "component---src-pages-cream-js": () => import("./../../../src/pages/cream.js" /* webpackChunkName: "component---src-pages-cream-js" */),
  "component---src-pages-cream-rolls-js": () => import("./../../../src/pages/cream-rolls.js" /* webpackChunkName: "component---src-pages-cream-rolls-js" */),
  "component---src-pages-crunchy-rolls-js": () => import("./../../../src/pages/crunchy-rolls.js" /* webpackChunkName: "component---src-pages-crunchy-rolls-js" */),
  "component---src-pages-distribucija-js": () => import("./../../../src/pages/distribucija.js" /* webpackChunkName: "component---src-pages-distribucija-js" */),
  "component---src-pages-en-about-us-js": () => import("./../../../src/pages/en/about-us.js" /* webpackChunkName: "component---src-pages-en-about-us-js" */),
  "component---src-pages-en-blog-js": () => import("./../../../src/pages/en/blog.js" /* webpackChunkName: "component---src-pages-en-blog-js" */),
  "component---src-pages-en-choco-cream-rolls-js": () => import("./../../../src/pages/en/choco-cream-rolls.js" /* webpackChunkName: "component---src-pages-en-choco-cream-rolls-js" */),
  "component---src-pages-en-cocoa-waltz-cake-js": () => import("./../../../src/pages/en/cocoa-waltz-cake.js" /* webpackChunkName: "component---src-pages-en-cocoa-waltz-cake-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-cream-js": () => import("./../../../src/pages/en/cream.js" /* webpackChunkName: "component---src-pages-en-cream-js" */),
  "component---src-pages-en-cream-rolls-js": () => import("./../../../src/pages/en/cream-rolls.js" /* webpackChunkName: "component---src-pages-en-cream-rolls-js" */),
  "component---src-pages-en-crunchy-rolls-js": () => import("./../../../src/pages/en/crunchy-rolls.js" /* webpackChunkName: "component---src-pages-en-crunchy-rolls-js" */),
  "component---src-pages-en-distribution-js": () => import("./../../../src/pages/en/distribution.js" /* webpackChunkName: "component---src-pages-en-distribution-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-waltz-bakes-for-cakes-js": () => import("./../../../src/pages/en/waltz-bakes-for-cakes.js" /* webpackChunkName: "component---src-pages-en-waltz-bakes-for-cakes-js" */),
  "component---src-pages-en-waltz-block-js": () => import("./../../../src/pages/en/waltz-block.js" /* webpackChunkName: "component---src-pages-en-waltz-block-js" */),
  "component---src-pages-en-waltz-cream-js": () => import("./../../../src/pages/en/waltz-cream.js" /* webpackChunkName: "component---src-pages-en-waltz-cream-js" */),
  "component---src-pages-en-waltz-cream-rolls-js": () => import("./../../../src/pages/en/waltz-cream-rolls.js" /* webpackChunkName: "component---src-pages-en-waltz-cream-rolls-js" */),
  "component---src-pages-en-waltz-roll-choko-js": () => import("./../../../src/pages/en/waltz-roll-choko.js" /* webpackChunkName: "component---src-pages-en-waltz-roll-choko-js" */),
  "component---src-pages-en-waltz-roll-js": () => import("./../../../src/pages/en/waltz-roll.js" /* webpackChunkName: "component---src-pages-en-waltz-roll-js" */),
  "component---src-pages-en-waltz-roll-mini-js": () => import("./../../../src/pages/en/waltz-roll-mini.js" /* webpackChunkName: "component---src-pages-en-waltz-roll-mini-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nama-js": () => import("./../../../src/pages/o-nama.js" /* webpackChunkName: "component---src-pages-o-nama-js" */),
  "component---src-pages-waltz-block-js": () => import("./../../../src/pages/waltz-block.js" /* webpackChunkName: "component---src-pages-waltz-block-js" */),
  "component---src-pages-waltz-cream-js": () => import("./../../../src/pages/waltz-cream.js" /* webpackChunkName: "component---src-pages-waltz-cream-js" */),
  "component---src-pages-waltz-cream-rolls-js": () => import("./../../../src/pages/waltz-cream-rolls.js" /* webpackChunkName: "component---src-pages-waltz-cream-rolls-js" */),
  "component---src-pages-waltz-kore-za-tortu-js": () => import("./../../../src/pages/waltz-kore-za-tortu.js" /* webpackChunkName: "component---src-pages-waltz-kore-za-tortu-js" */),
  "component---src-pages-waltz-roll-choko-js": () => import("./../../../src/pages/waltz-roll-choko.js" /* webpackChunkName: "component---src-pages-waltz-roll-choko-js" */),
  "component---src-pages-waltz-roll-js": () => import("./../../../src/pages/waltz-roll.js" /* webpackChunkName: "component---src-pages-waltz-roll-js" */),
  "component---src-pages-waltz-roll-mini-js": () => import("./../../../src/pages/waltz-roll-mini.js" /* webpackChunkName: "component---src-pages-waltz-roll-mini-js" */)
}

